export default {
  WAREHOUSES_LIST: "Liste des entrepôts",
  ADD_WAREHOUSE: "Ajouter un entrepôt",
  WAREHOUSE_ADDED: "Entrepôt ajouté",
  WAREHOUSE_IS_MODEL: "Est-ce un modèle ?",
  WAREHOUSE_MODEL: "Modèle appliqué",
  WAREHOUSE_MODEL_USED: "Modèle utilisé",
  EDIT_WAREHOUSE: "Modifier un entrepôt",
  WAREHOUSE_UPDATED: "Entrepôt mis à jour",
  DELETE_THIS_WAREHOUSE: "Supprimer ce entrepôt ?",
  WAREHOUSE_DELETED: "Entrepôt supprimé",
  APPLIED_AT: "Appliqué le",
  WAREHOUSE_TO_CONFIGURE: "Associer des entrepôts",
  ALLOW_NEGATIVE_INVENTORY: "Autoriser un stock negatif",
  ALLOW_UNREGISTERED_PRODUCTS: "Autoriser les produits non enregistrés",
  APPLY_CONFIGURATION:
    "Toutes les configurations des entrepots seront mises a jour",
  USE_WAREHOUSE_TAXES: "Utiliser des taxes personalisées",
  CONFIGURATION_RESULTS: "Resultat des configurations",
  PRODUCTS_CREATED: "Créés",
  PRODUCTS_UPDATED: "Mis à jour",
  PRODUCTS_DELETED: "Supprimés",
  PRODUCTS_ERRORS: "Erreurs",
  PRODUCT: "Produit",
  ERROR: "Erreur",
};
