<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="appNavBarClass"
  >
    <div
      class="navbar-search form-inline mr-sm-3 mb-0"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <i :class="appModuleIcon"></i>
      <h1 class="display-5 mb-0 ml-2">
        {{ appModuleName ? appModuleName : appConfig.config_manager_app_name }}
      </h1>
    </div>
    <!-- Search form -->
    <!--<form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text" />
        </div>
      </div>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </form>-->
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>

      <!-- NOTIFICATIONS -->
      <dashboard-navbar-notifications />

      <!-- APPS selector -->
      <dashboard-navbar-apps />
    </ul>

    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
            <span
              class="avatar avatar-sm rounded-circle avatar-image"
              :style="{
                'background-image': `url('${profileImage}')`,
              }"
            >
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ title }}</span>
            </div>
          </div>
        </a>

        <template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">{{ $t("COMMON.WELCOME") }}!</h6>
          </div>
          <router-link
            :to="{ name: 'View Profile' }"
            class="dropdown-item"
            v-slot="{ navigate }"
            custom
          >
            <span>
              <i class="ni ni-single-02"></i>
              <span @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("COMMON.MY_PROFILE") }}
              </span>
            </span>
          </router-link>
          <div class="dropdown-divider"></div>
          <a @click.prevent="logout()" href="#" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>{{ $t("COMMON.LOGOUT") }}</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { mapGetters } from "vuex";
import { BaseNav } from "@/components";
import DashboardNavbarNotifications from "./partials/DashboardNavbarNotifications.vue";
import DashboardNavbarApps from "./partials/DashboardNavbarApps.vue";

export default {
  components: {
    BaseNav,
    DashboardNavbarNotifications,
    DashboardNavbarApps,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
    appModuleName: {
      type: String,
      default: null,
    },
    appModuleIcon: {
      type: String,
      default: "ni ni-2x ni-app",
    },
    appNavBarClass: {
      type: String,
      default: "bg-success navbar-dark",
    },
  },

  async created() {
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.title = `${me.firstname} ${me.lastname}`;
        this.avatar = me.profile_image;
      }
    );
  },

  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    profileImage() {
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },

  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      title: "Profile",
      avatar: null,
    };
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async logout() {
      try {
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },
};
</script>

<style lang="scss">
// Custom style for responsive
body {
  &.g-sidenav-show {
    .navbar-collapse {
      @media (max-width: 1200px) {
        .navbar-nav:nth-child(1) {
          margin-left: 250px !important;
        }
      }
    }
  }

  .navbar-collapse {
    @media (max-width: 1200px) {
      .navbar-nav:nth-child(1) {
        -webkit-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        margin-left: 0 !important;
      }

      @media (max-width: 992px) {
        .navbar-nav:nth-child(2) {
          margin-left: auto;
          margin-right: auto;
        }
      }

      @media (max-width: 768px) {
        .navbar-nav:nth-child(3) {
          margin-left: 0 !important;
        }
      }
    }
  }

  .dropdown-menu-lg {
    min-width: 500px;
    border-radius: 0.4375rem;
  }
}
</style>
