var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_LOCATIONS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.LOCATIONS'),
          icon: 'ni ni-map-big text-primary',
          path: '/admin/locations',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_ORGANIZATIONS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.ORGANIZATIONS'),
          icon: 'ni ni-building text-primary',
          path: '/admin/organizations',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLERS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.RESELLERS'),
          icon: 'ni ni-shop text-primary',
          path: '/admin/resellers',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_USERS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.USERS'),
          icon: 'ni ni-single-02 text-primary',
          path: '/admin/users',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_ROLES))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.ROLES'),
          icon: 'ni ni-hat-3 text-primary',
          path: '/admin/roles',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_TAXES))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.TAX_GROUPS'),
          icon: 'fa fa-wallet text-primary',
          path: '/admin/tax-groups',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_TAXES))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.TAXES'),
          icon: 'fa fa-money-bill text-primary',
          path: '/admin/taxes',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_IMPORTS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.IMPORTS'),
          icon: 'ni ni-hat-3 text-primary',
          path: '/admin/imports',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_OPTIONS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.OPTIONS'),
          icon: 'fa fa-cogs text-primary',
          path: '/admin/options',
        }}}):_vm._e(),_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}}),_c('lang-switcher')],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType,"appModuleIcon":'ni ni-2x ni-settings-gear-65',"appModuleName":_vm.$t('APPS.ADMIN'),"appNavBarClass":'bg-gray navbar-dark'}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }