import service from "@/store/services/options-service";

export const state = () => ({
  list: {},
  option: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, option) => {
    state.option = option;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((option) => {
      commit("SET_RESOURCE", option);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  option: (state) => state.option,
  url: (state) => state.url,
};

const options = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default options;
