import permissions from "../constants/permissions";
import InventoryModuleLayout from "@/views/Pages/InventoryModule/InventoryModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/ListProductPage.vue");
const AddProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/AddProductPage.vue");
const EditProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/EditProductPage.vue");
const ViewProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/ViewProductPage.vue");

const ListUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/ListUnitOfMeasurePage.vue"
  );
const AddUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/AddUnitOfMeasurePage.vue"
  );
const EditUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/EditUnitOfMeasurePage.vue"
  );
const ViewUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/ViewUnitOfMeasurePage.vue"
  );

const ListWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/ListWarehousePage.vue"
  );
const AddWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/AddWarehousePage.vue"
  );
const EditWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/EditWarehousePage.vue"
  );
const ViewWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/ViewWarehousePage.vue"
  );

const ListStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/ListStockMovementPage.vue"
  );
const AddStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/AddStockMovementPage.vue"
  );
const EditStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/EditStockMovementPage.vue"
  );
const ViewStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/ViewStockMovementPage.vue"
  );

const ListCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/ListCategoryPage.vue"
  );
const AddCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/AddCategoryPage.vue"
  );
const EditCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/EditCategoryPage.vue"
  );
const ViewCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/ViewCategoryPage.vue"
  );
const InventoryPage = () =>
  import("@/views/Pages/InventoryModule/InventoryPage.vue");

let productManagementPages = {
  path: "products",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Products",
      components: { default: ListProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRODUCTS,
      },
    },
    {
      path: "add",
      name: "Add Product",
      components: { default: AddProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PRODUCTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Product",
      components: { default: EditProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PRODUCTS,
      },
    },
    {
      path: "view/:id",
      name: "View Product",
      components: { default: ViewProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PRODUCTS,
      },
    },
  ],
};

let unitOfMeasureManagementPages = {
  path: "unit-of-measures",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List UnitOfMeasures",
      components: { default: ListUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRODUCTS,
      },
    },
    {
      path: "add",
      name: "Add UnitOfMeasure",
      components: { default: AddUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PRODUCTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit UnitOfMeasure",
      components: { default: EditUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PRODUCTS,
      },
    },
    {
      path: "view/:id",
      name: "View UnitOfMeasure",
      components: { default: ViewUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PRODUCTS,
      },
    },
  ],
};

let warehouseManagementPages = {
  path: "warehouses",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Warehouses",
      components: { default: ListWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_WAREHOUSES,
      },
    },
    {
      path: "add",
      name: "Add Warehouse",
      components: { default: AddWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_WAREHOUSES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Warehouse",
      components: { default: EditWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_WAREHOUSES,
      },
    },
    {
      path: "view/:id",
      name: "View Warehouse",
      components: { default: ViewWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_WAREHOUSES,
      },
    },
  ],
};

let categoryManagementPages = {
  path: "categories",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Categories",
      components: { default: ListCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CATEGORIES,
      },
    },
    {
      path: "add",
      name: "Add Category",
      components: { default: AddCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CATEGORIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Category",
      components: { default: EditCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CATEGORIES,
      },
    },
    {
      path: "view/:id",
      name: "View Category",
      components: { default: ViewCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CATEGORIES,
      },
    },
  ],
};

let stockMovementManagementPages = {
  path: "stock-movements",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List StockMovements",
      components: { default: ListStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_WAREHOUSES,
      },
    },
    {
      path: "add",
      name: "Add StockMovement",
      components: { default: AddStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_WAREHOUSES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit StockMovement",
      components: { default: EditStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_WAREHOUSES,
      },
    },
    {
      path: "view/:id",
      name: "View StockMovement",
      components: { default: ViewStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_WAREHOUSES,
      },
    },
  ],
};

const inventoryModuleRoutes = [
  {
    path: "",
    component: InventoryModuleLayout,
    children: [
      {
        path: "",
        name: "Inventory Page",
        components: { default: InventoryPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  productManagementPages,
  unitOfMeasureManagementPages,
  warehouseManagementPages,
  stockMovementManagementPages,
  categoryManagementPages,
];

export default inventoryModuleRoutes;
