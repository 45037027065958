export default {
  PRODUCTS_LIST: "Liste des produits",
  ADD_PRODUCT: "Ajouter un produit",
  PRODUCT_ADDED: "Produit ajouté",
  EDIT_PRODUCT: "Modifier un produit",
  PRODUCT_UPDATED: "Produit mis à jour",
  DELETE_THIS_PRODUCT: "Supprimer ce produit ?",
  PRODUCT_DELETED: "Produit supprimé",
  SKU: "Sku",
  DELETE_THIS_SUPPLIER_PRODUCT: "Supprimer ce produit?",
  DELETE_THIS_WAREHOUSE_PRODUCT: "Supprimer ce produit?",
  PRODUCTS_SAVED: "Produits enregistrés",
  ADD_SUPPLIER: "Ajouter un fournisseur",
  STATUS_ACTIVE: "Actif",
  STATUS_INACTIVE: "Inactif",
  STATUS_DELETED: "Supprimé",
  SYNC_PRODUCT: "Synchroniser les produits",
  RUN_SYNCHRONIZE_PRODUCTS: "Exécuter la synchronisation",
  RUN_DRY_SYNCHRONIZE_PRODUCTS: "Tester la synchronisation",
  EXCLUDING_TAX: "Produit hors taxes",
  CUSTOM: "Custom",
  CUSTOM_PRICING: "Prix",
  CUSTOM_TAXATION: "Taxes",
  EMPTY_ADD_PRODUCTS:
    "La liste des produits à ajouter est vide ou les produits sélectionnés on été déjà ajoutés",
  CAN_BE_PURCHASED: "Peut être acheté",
  CAN_BE_SOLD: "Peut être vendu",
  SUPPLIER_CODE: "Code fournisseur",
  UNIT_OF_MEASURES_LIST: "Liste des unités de mesure",
  ADD_UNIT_OF_MEASURE: "Ajouter une unité de mesure",
  UNIT_OF_MEASURE_ADDED: "Unité de mesure ajoutés",
  EDIT_UNIT_OF_MEASURE: "Modifier uns unité de mesure",
  UNIT_OF_MEASURE_UPDATED: "Unité de mesure mis à jour",
  DELETE_THIS_UNIT_OF_MEASURE: "Supprimer cette unité de mesure ?",
  UNIT_OF_MEASURE_DELETED: "Unité de mesure supprimée",
  UNIT_RATIO: "Ratio",
  UNIT_TYPE: "Type",
  UNIT_TYPE_REFERENCE_UNIT: "Unité de reference",
  UNIT_TYPE_BIGGER_THAN_REFERENCE: "Plus grand que l'unité de reference",
  UNIT_TYPE_SMALLER_THAN_REFERENCE: "Plus petit que l'unité de reference",
  UNIT_OF_MEASURE_UNIT: "Unité",
};
