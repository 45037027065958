import permissions from "../constants/permissions";
import AdminModuleLayout from "@/views/Pages/AdminModule/AdminModuleLayout.vue";

//import middleware
import authorized from "@/middleware/authorized";
import authenticated from "@/middleware/authenticated";

//import pages
const AdminPage = () => import("@/views/Pages/AdminModule/AdminPage.vue");

const ListRolePage = () =>
  import("@/views/Pages/AdminModule/RoleManagement/ListRolePage.vue");
const AddRolePage = () =>
  import("@/views/Pages/AdminModule/RoleManagement/AddRolePage.vue");
const EditRolePage = () =>
  import("@/views/Pages/AdminModule/RoleManagement/EditRolePage.vue");
const ViewRolePage = () =>
  import("@/views/Pages/AdminModule/RoleManagement/ViewRolePage.vue");

const ListUserPage = () =>
  import("@/views/Pages/AdminModule/UserManagement/ListUserPage.vue");
const AddUserPage = () =>
  import("@/views/Pages/AdminModule/UserManagement/AddUserPage.vue");
const EditUserPage = () =>
  import("@/views/Pages/AdminModule/UserManagement/EditUserPage.vue");
const ViewUserPage = () =>
  import("@/views/Pages/AdminModule/UserManagement/ViewUserPage.vue");

const ListOrganizationPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationManagement/ListOrganizationPage.vue"
  );
const AddOrganizationPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationManagement/AddOrganizationPage.vue"
  );
const EditOrganizationPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationManagement/EditOrganizationPage.vue"
  );
const ViewOrganizationPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationManagement/ViewOrganizationPage.vue"
  );

const ListResellerPage = () =>
  import("@/views/Pages/AdminModule/ResellerManagement/ListResellerPage.vue");
const AddResellerPage = () =>
  import("@/views/Pages/AdminModule/ResellerManagement/AddResellerPage.vue");
const EditResellerPage = () =>
  import("@/views/Pages/AdminModule/ResellerManagement/EditResellerPage.vue");
const ViewResellerPage = () =>
  import("@/views/Pages/AdminModule/ResellerManagement/ViewResellerPage.vue");

const ListLocationPage = () =>
  import("@/views/Pages/AdminModule/LocationManagement/ListLocationPage.vue");
const AddLocationPage = () =>
  import("@/views/Pages/AdminModule/LocationManagement/AddLocationPage.vue");
const EditLocationPage = () =>
  import("@/views/Pages/AdminModule/LocationManagement/EditLocationPage.vue");
const ViewLocationPage = () =>
  import("@/views/Pages/AdminModule/LocationManagement/ViewLocationPage.vue");

const ListImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/ListImportPage.vue");
const AddImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/AddImportPage.vue");
const EditImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/EditImportPage.vue");
const ViewImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/ViewImportPage.vue");

const ListTaxPage = () =>
  import("@/views/Pages/AdminModule/TaxManagement/ListTaxPage.vue");
const AddTaxPage = () =>
  import("@/views/Pages/AdminModule/TaxManagement/AddTaxPage.vue");
const EditTaxPage = () =>
  import("@/views/Pages/AdminModule/TaxManagement/EditTaxPage.vue");
const ViewTaxPage = () =>
  import("@/views/Pages/AdminModule/TaxManagement/ViewTaxPage.vue");

const ListTaxGroupPage = () =>
  import("@/views/Pages/AdminModule/TaxGroupManagement/ListTaxGroupPage.vue");
const AddTaxGroupPage = () =>
  import("@/views/Pages/AdminModule/TaxGroupManagement/AddTaxGroupPage.vue");
const EditTaxGroupPage = () =>
  import("@/views/Pages/AdminModule/TaxGroupManagement/EditTaxGroupPage.vue");
const ViewTaxGroupPage = () =>
  import("@/views/Pages/AdminModule/TaxGroupManagement/ViewTaxGroupPage.vue");

const ListOptionPage = () =>
  import("@/views/Pages/AdminModule/OptionManagement/ListOptionPage.vue");

let userManagementPages = {
  path: "users",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Users",
      components: { default: ListUserPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_USERS,
      },
    },
    {
      path: "add",
      name: "Add User",
      components: { default: AddUserPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_USERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit User",
      components: { default: EditUserPage },
      meta: { middleware: authorized, permission: permissions.PERM_EDIT_USERS },
    },
    {
      path: "view/:id",
      name: "View User",
      components: { default: ViewUserPage },
      meta: { middleware: authorized, permission: permissions.PERM_EDIT_USERS },
    },
  ],
};

let roleManagementPages = {
  path: "roles",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ROLES,
      },
    },
    {
      path: "add",
      name: "Add Role",
      components: { default: AddRolePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_ROLES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Role",
      components: { default: EditRolePage },
      meta: { middleware: authorized, permission: permissions.PERM_EDIT_ROLES },
    },
    {
      path: "view/:id",
      name: "View Role",
      components: { default: ViewRolePage },
      meta: { middleware: authorized, permission: permissions.PERM_VIEW_ROLES },
    },
  ],
};

let resellerManagementPages = {
  path: "resellers",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Resellers",
      components: { default: ListResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLERS,
      },
    },
    {
      path: "add",
      name: "Add Reseller",
      components: { default: AddResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_RESELLERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Reseller",
      components: { default: EditResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_RESELLERS,
      },
    },
    {
      path: "view/:id",
      name: "View Reseller",
      components: { default: ViewResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_RESELLERS,
      },
    },
  ],
};

let organizationManagementPages = {
  path: "organizations",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Organizations",
      components: { default: ListOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ORGANIZATIONS,
      },
    },
    {
      path: "add",
      name: "Add Organization",
      components: { default: AddOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_ORGANIZATIONS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Organization",
      components: { default: EditOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_ORGANIZATIONS,
      },
    },
    {
      path: "view/:id",
      name: "View Organization",
      components: { default: ViewOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ORGANIZATIONS,
      },
    },
  ],
};

let locationsManagementPages = {
  path: "locations",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Locations",
      components: { default: ListLocationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_LOCATIONS,
      },
    },
    {
      path: "add",
      name: "Add Location",
      components: { default: AddLocationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_LOCATIONS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Location",
      components: { default: EditLocationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_LOCATIONS,
      },
    },
    {
      path: "view/:id",
      name: "View Location",
      components: { default: ViewLocationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_LOCATIONS,
      },
    },
  ],
};

let importManagementPages = {
  path: "imports",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Imports",
      components: { default: ListImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_IMPORTS,
      },
    },
    {
      path: "add",
      name: "Add Imports",
      components: { default: AddImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_IMPORTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Imports",
      components: { default: EditImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_IMPORTS,
      },
    },
    {
      path: "view/:id",
      name: "View Imports",
      components: { default: ViewImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_IMPORTS,
      },
    },
  ],
};

let taxManagementPages = {
  path: "taxes",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Taxes",
      components: { default: ListTaxPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_TAXES,
      },
    },
    {
      path: "add",
      name: "Add Tax",
      components: { default: AddTaxPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_TAXES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Tax",
      components: { default: EditTaxPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_TAXES,
      },
    },
    {
      path: "view/:id",
      name: "View Tax",
      components: { default: ViewTaxPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_TAXES,
      },
    },
  ],
};

let taxGroupManagementPages = {
  path: "tax-groups",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List TaxGroups",
      components: { default: ListTaxGroupPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_TAXES,
      },
    },
    {
      path: "add",
      name: "Add TaxGroup",
      components: { default: AddTaxGroupPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_TAXES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit TaxGroup",
      components: { default: EditTaxGroupPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_TAXES,
      },
    },
    {
      path: "view/:id",
      name: "View TaxGroup",
      components: { default: ViewTaxGroupPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_TAXES,
      },
    },
  ],
};

let optionManagementPages = {
  path: "options",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Options",
      components: { default: ListOptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_OPTIONS,
      },
    },
  ],
};

const adminModuleRoutes = [
  {
    path: "",
    component: AdminModuleLayout,
    children: [
      {
        path: "",
        name: "Admin Page",
        components: { default: AdminPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  userManagementPages,
  roleManagementPages,
  organizationManagementPages,
  locationsManagementPages,
  resellerManagementPages,
  importManagementPages,
  taxManagementPages,
  taxGroupManagementPages,
  optionManagementPages,
];

export default adminModuleRoutes;
